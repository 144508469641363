import './gameStatus.scss';
import { Table } from 'react-bootstrap';
import TargetSetter from './targetSetter';
import { Button } from 'react-bootstrap';
import API from '../../../api';
import { useState } from 'react';
import ConfirmationDialog from '../../../generic/confirmationDialog';

const GameStatus = ({ game, strategies, current, target }) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleDialogClose = async (confirmation) => {
    if (confirmation) {
      await API.removeAllPlayersFromGame(game);
    }
    setShowDialog(false);
  };

  return (
    <div className="game-status">
      <Table>
        <thead>
        <tr>
          <th>Стратегия</th>
          <th>Игроки</th>
        </tr>
        </thead>
        <tbody>
        {
          strategies.map(strategy => (
            <tr key={strategy}>
              <td>{strategy}</td>
              <td>
                <TargetSetter
                  game={game}
                  strategy={strategy}
                  currentTarget={target[strategy] || 0}
                  currentPlayers={current[strategy] || 0}
                />
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
      <Button variant="danger" className="player-removal-button" onClick={() => setShowDialog(true)}>
        Убрать всех игроков из <b>{game}</b>
      </Button>
      <ConfirmationDialog
        show={showDialog}
        text={`Вы действительно хотите убрать всех игроков из игры "${game}"?`}
        handleClose={handleDialogClose}
      />
    </div>
  );
};

export default GameStatus;
