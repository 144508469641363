import './core.scss';
import React, { useEffect, useRef, useState } from 'react';
import PlayerManager from './playerManager';
import { Button } from 'react-bootstrap';
import { Route, Routes, Navigate, Link } from 'react-router-dom';
import API from '../api';
import InstanceStatus from './instanceStatus';
import URLStatus from './urlStatus';
import ENV from '../env';

const Core = ({ removeToken }) => {
  const statusWebSocket = useRef(null);
  const livenessWebSocket = useRef(null);
  const [status, setStatus] = useState(null);
  const [liveness, setLiveness] = useState(null);
  const [strategies, setStrategies] = useState(null);
  const [urls, setUrls] = useState(null);

  useEffect(() => {
    statusWebSocket.current = API.statusWebSocket();
    livenessWebSocket.current = API.livenessWebSocket();
    return () => {
      statusWebSocket.current.close();
      livenessWebSocket.current.close();
    };
  }, []);

  useEffect(() => {
    const initStrategies = async () => {
      setStrategies(await API.availableStrategies());
    };

    const initUrls = async () => {
      setUrls(await API.urls());
    };

    initStrategies().catch(console.error);
    initUrls().catch(console.error);
  }, []);

  useEffect(() => {
    if (statusWebSocket.current === null)
      return;

    statusWebSocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (JSON.stringify(data) !== JSON.stringify(status))
        setStatus(data);
    };
  }, [status]);

  useEffect(() => {
    if (livenessWebSocket.current === null)
      return;

    livenessWebSocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (JSON.stringify(data) !== JSON.stringify(liveness)) {
        setLiveness(data);
      }
    };
  }, [liveness]);

  const initialized = [status, strategies, urls, liveness].every((i) => i !== null);

  return (
    <div className="core">
      <div className="nav-bar">
        <a target="_blank" href={`https://${ENV.E2E_HOST}/docs`} rel="noreferrer">
          <img src="/cannon.png" alt="e2e" />
        </a>
        <Link to="/player-management">Управление игроками</Link>
        <Link to="/instance-status">Состояние экземпляров сервиса</Link>
        <Link to="/game-urls">URL игр</Link>
        <div className="spacer"/>
        <Button variant="dark" onClick={removeToken}>Выход</Button>
      </div>
      <div className="core-body">
        {
          initialized
            ? (
              <Routes>
                <Route
                  path="/player-management"
                  element={<PlayerManager status={status} strategies={strategies}/>}
                />
                <Route
                  path="/instance-status"
                  element={<InstanceStatus overallInstanceStatus={status.instances} />}
                />
                <Route
                  path="/game-urls"
                  element={<URLStatus urlStatus={urls} liveness={liveness} />}
                />
                <Route path="*" element={<Navigate to="/player-management"/>}/>
              </Routes>
            )
            : <span>Инициализация...</span>
        }
      </div>
    </div>
  );
};

export default Core;
