import './playerManager.scss';
import React from 'react';
import GameStatus from './gameStatus';
import { Accordion } from 'react-bootstrap';
import OverallStatus from './overallStatus';


const PlayerManager = ({ strategies, status }) => (
  <div className="player-manager">
    <OverallStatus status={status} />
    <Accordion>
      {
        Object.keys(strategies).map(game => (
          <Accordion.Item key={game} eventKey={game}>
            <Accordion.Header>{game}</Accordion.Header>
            <Accordion.Body>
              <GameStatus
                game={game}
                strategies={strategies[game]}
                current={status.current[game] || {}}
                target={status.target[game] || {}}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  </div>
);

export default PlayerManager;
