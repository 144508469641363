import React from 'react';
import ReactDOM from 'react-dom/client';
import Entry from './entry';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Entry />
  </Router>
);
