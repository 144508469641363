import './tokenInputGroup.scss';
import { useState } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';


const TokenInputForm = ({ setToken }) => {
  const [value, setValue] = useState('');

  return (
    <div className="TokenInputGroup">
      <div className="TokenInputGroup-Inner">
        <InputGroup>
          <Form.Control
            placeholder="Токен доступа"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button onClick={() => setToken(value)}>
            Сохранить
          </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default TokenInputForm;
