import './overallStatus.scss';
import { Table } from 'react-bootstrap';
import { TARGET_PLAYERS_PER_POD } from '../../../constants';
import LoadBar from './loadBar';
import NukeButton from './nukeButton';


const calculatePlayers = (status) => {
  let acc = 0;
  for (const gameStatus of Object.values(status)) {
    for (const strategyPlayers of Object.values(gameStatus)) {
      acc += strategyPlayers;
    }
  }
  return acc;
};

const OverallStatus = ({ status }) => {
  const { current, target } = status;
  const currentCount = calculatePlayers(current);
  const targetCount = calculatePlayers(target);
  const podCount = Object.keys(status.instances).length;
  const currentLoad = targetCount / (TARGET_PLAYERS_PER_POD * podCount);

  return (
    <div className="overall-status">
      <h1>Статус</h1>
      <div className="controls">
        <div className="stats">
          <Table>
            <tbody>
              <tr>
                <td>Игроки:</td>
                <td>{currentCount} / {targetCount}</td>
              </tr>
              <tr>
                <td>Количество под:</td>
                <td>{podCount}</td>
              </tr>
              <tr>
                <td>Нагрузка:</td>
                <td><LoadBar load={currentLoad} /></td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="spacer" />
        <NukeButton />
      </div>
    </div>
  );
};

export default OverallStatus;
