import './urlStatus.scss';
import { Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Fragment } from 'react';


const getVariant = (liveness) => {
  if (liveness === true) {
    return 'alive';
  }
  if (liveness === false) {
    return 'dead';
  }
  return 'unknown';
};

const renderTooltip = (liveness, props) => {
  let text;
  if (liveness === true) {
    text = 'Сервис работает.';
  } else if (liveness === false) {
    text = 'Сервис недоступен.'
  } else {
    text = 'E2E сервис не может получить статус целевого сервиса.'
  }

  return <Tooltip {...props}>{text}</Tooltip>;
};

const TerminalNode = (text, url, liveness) => (
  <div className="terminal-node">
    <div className="spacer" />
    <a href={'https://' + url + '/docs'} target="_blank" rel="noreferrer">
      {text}
    </a>
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 200 }}
      overlay={(props) => renderTooltip(liveness, props)}
    >
      <div className={["status", getVariant(liveness)].join(' ')} />
    </OverlayTrigger>
  </div>
);

const buildRow = (game, urls, liveness) => (
  <Fragment key={game}>
    <tr>
      <td className="game" rowSpan="5">{game}</td>
      <td colSpan="2">
        {TerminalNode('Shared', urls.SHARED, liveness.SHARED)}
      </td>
      <td>{urls.SHARED}</td>
    </tr>
    <tr>
      <td colSpan="2">
        {TerminalNode('User Service', urls.USER_SERVICE, liveness.USER_SERVICE)}
      </td>
      <td>{urls.USER_SERVICE}</td>
    </tr>
    <tr>
      <td rowSpan="3">Websockets</td>
      <td>
        {TerminalNode('Lifecycle', urls.WS.LIFECYCLE, liveness.WS.LIFECYCLE)}
      </td>
      <td>{urls.WS.LIFECYCLE}</td>
    </tr>
    <tr>
      <td>
        {TerminalNode('Bets', urls.WS.BETS, liveness.WS.BETS)}
      </td>
      <td>{urls.WS.BETS}</td>
    </tr>
    <tr>
      <td>
        {TerminalNode('Chat', urls.WS.CHAT, liveness.WS.CHAT)}
      </td>
      <td>{urls.WS.CHAT}</td>
    </tr>
  </Fragment>
);

const URLStatus = ({ urlStatus, liveness }) => (
  <div className="url-status">
    <Table>
      <thead>
        <tr>
          <th>Игра</th>
          <th colSpan="2">Тип сервиса</th>
          <th>URL</th>
        </tr>
      </thead>
      <tbody>
        {
          Object.keys(urlStatus).map((game) => buildRow(game, urlStatus[game], liveness[game]))
        }
      </tbody>
    </Table>
  </div>
);

export default URLStatus;
