import './instanceStatus.scss';
import { Accordion, Table } from 'react-bootstrap';

const InstanceStatus = ({ overallInstanceStatus }) => (
  <div className="instance-status">
    <Accordion>
      {
        Object.keys(overallInstanceStatus).sort().map(instance => (
          <Accordion.Item key={instance} eventKey={instance}>
            <Accordion.Header>{instance}</Accordion.Header>
            <Accordion.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Игра</th>
                    <th>Стратегия</th>
                    <th>Квота</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    Object.entries(overallInstanceStatus[instance]).map(([game, gameStatus]) => (
                      Object.entries(gameStatus).map(([strategy, quota], index) => (
                        <tr key={game + strategy}>
                          { index === 0 ? <td rowSpan={Object.keys(gameStatus).length}>{game}</td> : null}
                          <td>{strategy}</td>
                          <td>{quota}</td>
                        </tr>
                      ))
                    ))
                  }
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  </div>
);

export default InstanceStatus;
