import { Button, Modal, InputGroup, Form } from 'react-bootstrap';
import { useState } from 'react';
import API from '../../../../api';

const TargetSetter = ({ game, strategy, currentPlayers, currentTarget }) => {
  const [show, setShow] = useState(false);
  const [newTarget, setNewTarget] = useState(currentTarget);

  const handleClose = () => {
    setShow(false);
    setNewTarget(currentTarget);
  };
  const handleShow = () => setShow(true);
  const handleSet = async () => {
    if (newTarget !== currentTarget) {
      await API.setPlayers(game, strategy, newTarget)
    }
    setShow(false);
  };
  const handleNewTargetChange = (event) => {
    if (event.target.value === '') {
      setNewTarget(0);
      return;
    }
    if (/^\d+$/.test(event.target.value)){
      setNewTarget(parseInt(event.target.value));
    }
  }

  return (
    <div className="target-setter">
      <InputGroup>
        <InputGroup.Text>{currentPlayers} / {currentTarget}</InputGroup.Text>
        <Button variant="outline-secondary" onClick={handleShow}>✏️</Button>
      </InputGroup>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>{game} -> {strategy}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Выберите желаемое количество игроков:
          <InputGroup className="mb-3">
            <Form.Control
              value={newTarget}
              onChange={handleNewTargetChange}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Отмена
          </Button>
          <Button variant="primary" onClick={handleSet}>
            Применить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TargetSetter;
