import { Modal, Button } from 'react-bootstrap';

const ConfirmationDialog = ({ show, text, handleClose }) => (
  <Modal show={show} onHide={() => handleClose(false)} centered>
    <Modal.Header>
      <Modal.Title>Подтвердите действие</Modal.Title>
    </Modal.Header>
    <Modal.Body>{ text }</Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => handleClose(true)}>
        Да
      </Button>
      <Button variant="secondary" onClick={() => handleClose(false)}>
        Нет
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmationDialog;
