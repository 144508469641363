let ENV = null;

const initEnv = async () => {
  const data = await fetch(process.env.PUBLIC_URL + '/env.json');
  ENV = await data.json();
};

await initEnv();


export default ENV;
