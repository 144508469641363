import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import API from '../../../../api';
import { Fragment, useState } from 'react';
import ConfirmationDialog from '../../../../generic/confirmationDialog';

const renderTooltip = (props) => <Tooltip {...props}>Убрать всех игроков со всех игр.</Tooltip>;

const NukeButton = () => {

  const [show, setShow] = useState(false);

  const handleDialogClose = async (confirmation) => {
    if (confirmation) {
      await API.removeAllPlayers();
    }
    setShow(false);
  }

  return (
    <Fragment>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 100 }}
        overlay={renderTooltip}
      >
        <Button variant="danger" className="nuke-button" onClick={() => setShow(true)}>💀</Button>
      </OverlayTrigger>
      <ConfirmationDialog
        show={show}
        text="Вы действительно хотите убрать всех игроков из всех игр?"
        handleClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default NukeButton;
