import axios from 'axios';
import ENV from './env';
import { TOKEN_NAME } from './constants';

const SECURE = true;
const HTTP_PROTOCOL = SECURE ? 'https' : 'http';
const WEBSOCKET_PROTOCOL = SECURE ? 'wss' : 'ws';

let axiosInstance = null;

const initAPI = (token) => {
  axiosInstance = axios.create({
    baseURL: [HTTP_PROTOCOL, '://', ENV.E2E_HOST].join(''),
    timeout: 3000,
  });
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};


class API {
  static token = () => localStorage.getItem(TOKEN_NAME);

  static statusWebSocket = () => new WebSocket(`${WEBSOCKET_PROTOCOL}://${ENV.E2E_HOST}/listen?token=${API.token()}`);
  static livenessWebSocket = () => new WebSocket(
    `${WEBSOCKET_PROTOCOL}://${ENV.E2E_HOST}/liveness?token=${API.token()}`
  );

  static availableStrategies = async () => {
    const { data } = await axiosInstance.get('/available-strategies');
    return data;
  };
  static probeToken = async () => {
    try {
      await axiosInstance.get('/available-strategies');
      return true;
    } catch (err) {
      return false;
    }
  }
  static setPlayers = async (game, strategy, count) => {
    await axiosInstance.post(`/players/${game}/set`, {strategy, count});
  };
  static removeAllPlayersFromGame = async (game) => {
    await axiosInstance.post(`/players/${game}/remove-all`);
  };
  static removeAllPlayers = async () => {
    await axiosInstance.post(`/players/remove-all`);
  };
  static urls = async () => {
    return (await axiosInstance.get('/urls')).data;
  };
}

export default API;
export { initAPI };
