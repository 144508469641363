import API, { initAPI } from '../api';
import Core from '../core';
import React, { useEffect, useState, useCallback } from 'react';
import TokenInputForm from './tokenInputForm';
import { TOKEN_NAME } from '../constants';


const Entry = () => {
  const [tokenIsPresent, setTokenIsPresent] = useState(null);
  const [tokenIsValid, setTokenIsValid] = useState(null);

  const setToken = useCallback(async (token) => {
    localStorage.setItem(TOKEN_NAME, token);
    initAPI(token);
    setTokenIsPresent(true);
    setTokenIsValid(null);
    const validated = await API.probeToken();
    if (!validated) {
      removeToken();
      alert('Токен не прошёл валидацию.')
      setTokenIsValid(false);
    } else {
      setTokenIsValid(true);
    }
  }, []);

  const removeToken = () => {
    localStorage.removeItem(TOKEN_NAME);
    setTokenIsPresent(false);
  };

  useEffect(() => {
    const currentToken = localStorage.getItem(TOKEN_NAME);
    if (currentToken !== null) {
      setToken(currentToken).then();
    } else {
      setTokenIsPresent(false);
    }
  }, [setToken]);

  if (tokenIsPresent === null)
    return <React.Fragment />;

  if (!tokenIsPresent)
    return <TokenInputForm setToken={setToken} />

  if (!tokenIsValid)
    return <React.Fragment />;

  return <Core removeToken={removeToken} />;
};

export default Entry;
