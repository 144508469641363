import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TARGET_PLAYERS_PER_POD } from '../../../../constants';


const getProgressBg = (load) => {
  if (load < 0.7) {
    return "bg-success";
  }
  if (load < 0.9) {
    return "bg-warning";
  }
  return "bg-danger";
};

const renderTooltip = (props) => (
  <Tooltip {...props}>
    Каждая пода имеет расчётную нагрузку в <b>{TARGET_PLAYERS_PER_POD}</b> игроков. Превышение данного лимита может
    повлечь за собой проблемы с процессом эмуляции игры. Если Вам требуется запустить больше игроков, обратитесь к
    команде DevOps или Backend с просьбой увеличить количество под E2E сервиса.
  </Tooltip>
);


const LoadBar = ({ load }) => {
  const loadPercentage = Math.round(load * 100);
  const cappedLoadPercentage = Math.min(100, loadPercentage);

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 200 }}
      overlay={renderTooltip}
    >
      <div className="progress">
        <div
          className={`progress-bar ${getProgressBg(load)}`}
          role="progressbar"
          style={{width: `${cappedLoadPercentage}%`}}
          aria-valuenow={cappedLoadPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {loadPercentage}%
        </div>
      </div>
    </OverlayTrigger>
  );
}

export default LoadBar;
